import react, { Component } from "react";
import "./Home.css";
import Banner from "../elements/Banner/Banner";
import CalendarFields from "../elements/CalendarFields/CalendarFields";
import ThreeColGrid from "../elements/ThreeColGrid/ThreeColGrid";

class Home extends Component {
  state = {
    rvData: null,
    reviewsData: null,
  };

  // Fetching RV and Review Items for HomePage and storing them into the state
  componentDidMount() {
    fetch("https://nhrvrentalapi.nhrvrental.com/get-home-rvs", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((findresponse) => {
        this.setState({
          rvData: findresponse,
        });
      });

    fetch("https://nhrvrentalapi.nhrvrental.com/get-home-reviews", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((findresponse) => {
        this.setState({
          reviewsData: findresponse,
        });
      });

    if (this.props.location.state) {
      if (this.props.location.state.redirected) {
        document.getElementsByClassName("start-date")[0].focus();
      }
    }
  }

  render() {
    return (
      <div className="home-wrapper">
        <Banner />
        <div className="gray-section">
          <div className="boxed-content about-us">
            <h1>LUXURY RV RENTAL</h1>
            <p>
              <span>NH RV Rental</span> is an affordable Luxury RV Rental company
              specializing in Light Weight, Luxurious, All Packed, Clean & Ready
              to Tow Hotels on Wheels. We pick up where other rental companies
              leave off. With delivery options available, we will be sure to
              take the hassle out of camping and let you relax and enjoy the
              most from your camping vacation! We are in our sixth year of
              business with all Five-Star Reviews and we are expanding our fleet
              fast. Our prices and rental experience will not be beat, contact
              us today to enjoy the Ultimate Camping Experience!
            </p>
            <p>
              As the owner of the business, I started RV'ing with my family in 2018
              and am absolutely hooked on it! Nothing can beat the convenience of
              having a second home on wheels while enjoying America's finest
              attractions! With our RVs, we hope to share this wonderful
              experience with you and your family. We believe that the best way
              to be introduced to RV'ing is to rent an RV and try it for
              yourself. We assure, that you too will also be hooked, and
              hopefully you will enjoy camping as much as we do!
            </p>
          </div>
        </div>
        <div className="boxed-content">
          <ThreeColGrid title="NEWEST RVs" rvData={this.state.rvData} />
        </div>
        <div className="bg-image-wrapper">
          <div className="overlay"></div>
          <img className="bg-image" src="./images/banner-image-footer.jpg" />
          <div className="bg-image-content boxed-content">
            <ThreeColGrid
              title="LATEST REVIEWS"
              reviewsData={this.state.reviewsData}
              home={true}
            />
            <Banner footer={true} />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
