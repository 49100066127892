import React, { useState } from "react";
import "./Contact.css";

const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [sentMessage, setSentMessage] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [touched, setTouched] = useState(false);

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const checkEmail = () => {
    let error = false;
    const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!mailRegex.test(email) && email != "") {
      setErrorEmail(true);
      setTouched(false);
      error = true;
    } else {
      setErrorEmail(false);
      if (!touched && phone != "") {
        setTouched(true);
      }
    }

    return error;
  };

  const checkPhone = () => {
    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    if (!phoneRegex.test(phone)) {
      setErrorPhone(true);
      setTouched(false);
      return true;
    } else {
      setErrorPhone(false);
      if (!touched && email != "") {
        setTouched(true);
      }
    }

    return false;
  };

  const sendEmail = () => {
    if (sendingMessage) {
      return;
    }

    let error = false;

    if (checkEmail()) {
      error = true;
    }

    if (checkPhone()) {
      error = true;
    }

    if (message == "") {
      setErrorMessage(true);
      error = true;
    }

    if (error) {
      return;
    }

    setSendingMessage(true);

    fetch("https://nhrvrentalapi.nhrvrental.com/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email: email,
          message: message,
        },
      }),
    })
      .then((r) => {
        return r.json();
      })
      .then((res) => {
        setSendingMessage(false);
        setSentMessage(true);
        if (res.sent) {
          document.getElementById("sentMsg").innerHTML =
            "Thank you for getting in touch! We will reply by email as soon as possible.";
          document.getElementById("sentMsg").style.background = "#38a638";
        } else {
          document.getElementById("sentMsg").innerHTML =
            "Something went wrong, try again.";
          document.getElementById("sentMsg").style.background = "#b90000";
        }
        window.scrollTo(0, 0);
        resetForm();
      });
  };

  return (
    <div className="contact-wrapper boxed-content">
      <h1>CONTACT US</h1>
      <div className="contact-content">
        <div className="left">
          <div className="contact-info">
            <h3>Contact Information</h3>
            <div className="item">
              <a href="tel:6032422352">(603) 242-2352</a>
            </div>
            <div className="item">
              <a href="mailto:sales@nhrvrental.com">sales@nhrvrental.com</a>
            </div>
          </div>
          <div className="working-hours">
            <h3>Working Hours</h3>
            <div className="items">
              <div className="item">
                <span>Mon:</span>8:00 AM - 5:00 PM
              </div>
              <div className="item">
                <span>Tue:</span>8:00 AM - 5:00 PM
              </div>
              <div className="item">
                <span>Wed:</span>8:00 AM - 5:00 PM
              </div>
              <div className="item">
                <span>Thu:</span>8:00 AM - 5:00 PM
              </div>
              <div className="item">
                <span>Fri:</span>8:00 AM - 5:00 PM
              </div>
              <div className="item">
                <span>Sat:</span>Closed
              </div>
              <div className="item">
                <span>Sun:</span>Closed
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          {sentMessage && <div id="sentMsg" className="sent-msg"></div>}
          <div className="two-col">
            <div className="field-wrapper">
              <label htmlFor="firstName">First Name</label>
              <input
                className="contact-field"
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>
            <div className="field-wrapper">
              <label htmlFor="lastName">Last Name</label>
              <input
                className="contact-field"
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
          <div className="two-col">
            <div className="field-wrapper">
              <label htmlFor="phone">Phone number</label>
              <input
                className={
                  "contact-field " + (errorPhone ? "error-field" : null)
                }
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                onKeyUp={() => checkPhone()}
              />
              {errorPhone ? (
                <span className="error-msg">Invalid phone format.</span>
              ) : null}
            </div>
            <div className="field-wrapper">
              <label htmlFor="email">Email</label>
              <input
                className={
                  "contact-field " + (errorEmail ? "error-field" : null)
                }
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onKeyUp={() => checkEmail()}
              />
              {errorEmail && email != "" ? (
                <span className="error-msg">Invalid email format.</span>
              ) : null}
              {errorEmail && email == "" ? (
                <span className="error-msg">Email required.</span>
              ) : null}
            </div>
          </div>
          <div className="field-wrapper">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              onKeyDown={() => {
                if (errorMessage) {
                  setErrorMessage(false);
                }
              }}
            ></textarea>
            {errorMessage ? (
              <span className="error-msg">Message required.</span>
            ) : null}
          </div>
          <div
            className={
              "blue-btn send-btn" +
              (errorEmail || errorPhone || errorMessage || !touched
                ? " disabled"
                : "")
            }
            onClick={sendEmail}
          >
            Send
            {sendingMessage ? (
              <img className="email-loader" src="./images/loader-white.gif" />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
