import React, { Component } from "react";
import "./RV-List.css";
import ThreeColGrid from "../elements/ThreeColGrid/ThreeColGrid";

class RVList extends Component {
  // If we have 'props.location.state.data' it means that we are coming to this page from 'Find Available RVs' option
  // So we are using that data (which contains IDs of available RVs) to filter our search
  // Also, we are storing start and end date in the same data.
  constructor(props) {
    super(props);
    this.state = {
      idList: props.location.state ? props.location.state.idList.avail : null,
      startDate: props.location.state ? props.location.state.start : null,
      endDate: props.location.state ? props.location.state.end : null,
      rvList: null,
    };
  }

  // Fetching Complete RV List
  componentDidMount() {
    fetch("https://nhrvrentalapi.nhrvrental.com/get-rvs", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((findresponse) => {
        if (this.state.idList != null) {
          let availableRVS = new Array();

          this.state.idList.forEach((e) => {
            findresponse.forEach((element) => {
              if (e == element.id) {
                availableRVS.push(element);
              }
            });
          });
          this.setState({
            rvList: availableRVS,
          });
        } else {
          this.setState({
            rvList: findresponse,
          });
        }
      });
  }

  render() {
    return (
      <div className="rv-list-wrapper boxed-content">
        <ThreeColGrid
          title={this.props.location.state ? "Search results" : "OUR RVs"}
          rvData={this.state.rvList}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          list={true}
        />
      </div>
    );
  }
}

export default RVList;
