import React from "react";
import Navigation from "../Navigation/Navigation";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content boxed-content">
        <div className="left">
          <img className="footer-logo" src="./images/NHRV_DarkBG.svg" />
          <div className="below-footer">KGR Technologies, LLC</div>
        </div>
        <div className="right">
          <a href="tel:603-242-2352" className="phone">
            Call (603) 242-2352
          </a>
          <Navigation footer={true} />
        </div>
        <div className="copyright">Copyright 2023 | All rights reserved</div>
      </div>
     
    </div>
  );
};

export default Footer;
