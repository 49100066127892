import React from 'react';
import './Navigation.css';
import { NavLink } from 'react-router-dom';

const Navigation = (props) => {

    return (
        <div className={"navigation " + (props.footer ? "nav-footer" : "")} onClick={props.callback}>
            <NavLink to="/" activeClassName="active" exact>
                Home
            </NavLink>
            <NavLink to="/rv-list" activeClassName="active" exact>
                RVs
            </NavLink>
            <NavLink to="/amenities" activeClassName="active" exact>
                Amenities & Add Ons
            </NavLink>
            <NavLink to="/policies" activeClassName="active" exact>
                Policies & FAQs
            </NavLink>
            <NavLink to="/reviews" activeClassName="active" exact>
                Reviews
            </NavLink>
            <NavLink to="/contact-us" activeClassName="active" exact>
                Contact Us
            </NavLink>
        </div>
    )
}

export default Navigation;