import React from "react";
import GridElement from "../GridElement/GridElement";
import "./ThreeColGrid.css";

const ThreeColGrid = (props) => {
  /* 
        Function for rendering elements based on their type, so that we can use the same GridElement
        regardless of it being a RV or a Review. 
        In the first IF, we're checking if property 'rvData' exists. If it does, then it means
        that we are passing RV List as a property. 
        Same applies for the second IF, only for Reviews.
        And finally, if none of the above is true, there is an error.
    */

  const renderElements = () => {
    if (props.rvData) {
      // Render RV Box
      return props.rvData.map((element, i) => {
        return (
          <GridElement
            key={i}
            images={element.rvImages}
            name={element.rvName}
            description={element.rvDescription}
            options={element.options}
            id={element.id}
            startDate={props.startDate}
            endDate={props.endDate}
            price={element.pricePerNight}
            list={props.list}
          />
        );
      });
    } else if (props.reviewsData) {
      // Render Review Box
      return props.reviewsData.map((element, i) => {
        return (
          <GridElement
            key={i}
            text={element.text}
            name={element.name}
            date={element.date}
          />
        );
      });
    } else {
      return <div className="error">Error fetching list.</div>;
    }
  };

  // props.title is the name of current section that we are rendering (RVs or Reviews)
  // Received from Home component as a property
  const divClass = 'three-col-grid-wrapper ' + (props.home && 'home');
  return (
    <div className={divClass}>
      <h1>{props.title}</h1>
      <div className="three-col-grid">
        {renderElements()}
      </div>
    </div>
  );
};

export default ThreeColGrid;
