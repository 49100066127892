import React from 'react';
import { NavLink } from 'react-router-dom';
import './GridElement.css';

const GridElement = (props) => {

    // If we have image, and don't have review property, it means that we should render a RV Element
    // In other case, we should render a Review Element.
    const isRV = (props.images && props.text == null ? true : false);

    const renderRv = () => {
        return (
            <div className="rv-element">
                <NavLink to={{pathname: '/rv-details', state: {data: props, startDate: props.startDate, endDate: props.endDate}}}>
                    <div className="box rv-box" style={{backgroundImage: `url(${props.images[0]})`}}>
                    </div>
                </NavLink>              
                <h3 className="rv-name">
                    {props.name}
                </h3>
                {props.list ? <div className="price">${props.price} per night</div> : null}
                <NavLink to={{pathname: '/rv-details', state: {data: props, startDate: props.startDate, endDate: props.endDate}}}>
                    See details & availability
                </NavLink>
            </div>
        )
    }

    const renderReview = () => {
        return (
            <div className="box review-box">
                <img src="./images/left-quote.svg" />
                <p className="text">
                    {props.text}
                </p>
                <div className="info">
                    <div className="name">
                        {props.name}
                    </div>
                    <div className="location">
                        {props.date}
                    </div>
                </div>
            </div>
        )
    }

    return (
        isRV ? renderRv() : renderReview()
    )
}

export default GridElement;